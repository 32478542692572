/* Themes */
.theme--light.v-icon {
  color: rgba(0,0,0,0.54);
}
.theme--light.v-icon.v-icon--disabled {
  color: rgba(0,0,0,0.38) !important;
}
.theme--dark.v-icon {
  color: #fff;
}
.theme--dark.v-icon.v-icon--disabled {
  color: rgba(255,255,255,0.5) !important;
}
.v-icon {
  align-items: center;
  display: inline-flex;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  font-size: 24px;
  justify-content: center;
  line-height: 1;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  vertical-align: text-bottom;
}
.v-icon--right {
  margin-left: 16px;
}
.v-icon--left {
  margin-right: 16px;
}
.v-icon.v-icon.v-icon--link {
  cursor: pointer;
}
.v-icon--disabled {
  pointer-events: none;
  opacity: 0.6;
}
.v-icon--is-component {
  height: 24px;
}
