.v-rating .v-icon {
  padding: 0.5rem;
  border-radius: 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-rating--readonly .v-icon {
  pointer-events: none;
}
.v-rating--dense .v-icon {
  padding: 0.1rem;
}
