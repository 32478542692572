.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
  color: rgba(0,0,0,0.87);
}
.theme--light.v-date-picker-header .v-date-picker-header__value--disabled button {
  color: rgba(0,0,0,0.38);
}
.theme--dark.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
  color: #fff;
}
.theme--dark.v-date-picker-header .v-date-picker-header__value--disabled button {
  color: rgba(255,255,255,0.5);
}
.v-date-picker-header {
  padding: 4px 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.v-date-picker-header .v-btn {
  margin: 0;
  z-index: auto;
}
.v-date-picker-header .v-icon {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-date-picker-header__value {
  flex: 1;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.v-date-picker-header__value div {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-date-picker-header__value button {
  cursor: pointer;
  font-weight: bold;
  outline: none;
  padding: 0.5rem;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-date-picker-header--disabled {
  pointer-events: none;
}
