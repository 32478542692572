.v-bottom-sheet.v-dialog {
  align-self: flex-end;
  border-radius: 0;
  flex: 1 0 100%;
  margin: 0;
  min-width: 100%;
  overflow: visible;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
  max-width: 70%;
  min-width: 0;
}
@media only screen and (max-width: 599px) {
  .v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
    max-width: none;
  }
}
