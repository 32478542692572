.v-avatar {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  position: relative;
  text-align: center;
  vertical-align: middle;
}
.v-avatar img,
.v-avatar .v-icon,
.v-avatar .v-image {
  border-radius: 50%;
  display: inline-flex;
  height: inherit;
  width: inherit;
}
.v-avatar--tile {
  border-radius: 0;
}
.v-avatar--tile img,
.v-avatar--tile .v-icon,
.v-avatar--tile .v-image {
  border-radius: 0;
}
