/* Theme */
.theme--light.v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb {
  background: #bdbdbd;
}
.theme--dark.v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb {
  background: #424242;
}
/** Input Group */
.v-input--range-slider.v-input--is-disabled .v-slider__track-fill {
  display: none;
}
.v-input--range-slider.v-input--is-disabled.v-input--slider .v-slider.v-slider .v-slider__thumb {
  border-color: transparent;
}
