.theme--light.v-subheader {
  color: rgba(0,0,0,0.54);
}
.theme--dark.v-subheader {
  color: rgba(255,255,255,0.7);
}
.v-subheader {
  align-items: center;
  display: flex;
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px 0 16px;
}
.v-subheader--inset {
  margin-left: 56px;
}
