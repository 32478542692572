/** Theme */
.theme--light.v-input--switch__thumb {
  color: #fafafa;
}
.theme--light.v-input--switch__track {
  color: rgba(0,0,0,0.38);
}
.theme--light.v-input--switch.v-input--is-disabled .v-input--switch__thumb {
  color: #bdbdbd !important;
}
.theme--light.v-input--switch.v-input--is-disabled .v-input--switch__track {
  color: rgba(0,0,0,0.12) !important;
}
.theme--dark.v-input--switch__thumb {
  color: #bdbdbd;
}
.theme--dark.v-input--switch__track {
  color: rgba(255,255,255,0.3);
}
.theme--dark.v-input--switch.v-input--is-disabled .v-input--switch__thumb {
  color: #424242 !important;
}
.theme--dark.v-input--switch.v-input--is-disabled .v-input--switch__track {
  color: rgba(255,255,255,0.1) !important;
}
.application--is-rtl .v-input--switch .v-input--selection-controls__ripple {
  left: auto;
  right: -14px;
}
.application--is-rtl .v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple,
.application--is-rtl .v-input--switch.v-input--is-dirty .v-input--switch__thumb {
  -webkit-transform: translate(-16px, 0);
          transform: translate(-16px, 0);
}
.v-input--switch__track,
.v-input--switch__thumb {
  background-color: currentColor;
  pointer-events: none;
  transition: inherit;
}
.v-input--switch__track {
  border-radius: 8px;
  height: 14px;
  left: 2px;
  opacity: 0.6;
  position: absolute;
  right: 2px;
  top: calc(50% - 7px);
}
.v-input--switch__thumb {
  border-radius: 50%;
  top: calc(50% - 10px);
  height: 20px;
  position: relative;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.v-input--switch .v-input--selection-controls__input {
  width: 38px;
}
.v-input--switch .v-input--selection-controls__ripple {
  left: -14px;
  top: calc(50% - 24px);
}
.v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple,
.v-input--switch.v-input--is-dirty .v-input--switch__thumb {
  -webkit-transform: translate(16px, 0);
          transform: translate(16px, 0);
}
