.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(0,0,0,0.42);
}
.theme--light.v-text-field:not(.v-input--has-state) > .v-input__control > .v-input__slot:hover:before {
  border-color: rgba(0,0,0,0.87);
}
.theme--light.v-text-field.v-input--is-disabled > .v-input__control > .v-input__slot:before {
  border-image: repeating-linear-gradient(to right, rgba(0,0,0,0.38) 0px, rgba(0,0,0,0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
}
.theme--light.v-text-field.v-input--is-disabled > .v-input__control > .v-input__slot:before .v-text-field__prefix,
.theme--light.v-text-field.v-input--is-disabled > .v-input__control > .v-input__slot:before .v-text-field__suffix {
  color: rgba(0,0,0,0.38);
}
.theme--light.v-text-field__prefix,
.theme--light.v-text-field__suffix {
  color: rgba(0,0,0,0.54);
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border-radius: 2px;
  background: #fff;
}
.theme--light.v-text-field--solo-inverted.v-text-field--solo > .v-input__control > .v-input__slot {
  background: rgba(0,0,0,0.16);
}
.theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #424242;
}
.theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot .v-label,
.theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot input {
  color: #fff;
}
.theme--light.v-text-field--box > .v-input__control > .v-input__slot {
  background: rgba(0,0,0,0.06);
}
.theme--light.v-text-field--box .v-text-field__prefix {
  max-height: 32px;
  margin-top: 22px;
}
.theme--light.v-text-field--box.v-input--is-dirty .v-text-field__prefix,
.theme--light.v-text-field--box.v-input--is-focused .v-text-field__prefix,
.theme--light.v-text-field--box.v-text-field--placeholder .v-text-field__prefix {
  margin-top: 22px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.theme--light.v-text-field--box:not(.v-input--is-focused) > .v-input__control > .v-input__slot:hover {
  background: rgba(0,0,0,0.12);
}
.theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
  border: 2px solid rgba(0,0,0,0.54);
}
.theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  border: 2px solid rgba(0,0,0,0.87);
}
.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(255,255,255,0.7);
}
.theme--dark.v-text-field:not(.v-input--has-state) > .v-input__control > .v-input__slot:hover:before {
  border-color: #fff;
}
.theme--dark.v-text-field.v-input--is-disabled > .v-input__control > .v-input__slot:before {
  border-image: repeating-linear-gradient(to right, rgba(255,255,255,0.5) 0px, rgba(255,255,255,0.5) 2px, transparent 2px, transparent 4px) 1 repeat;
}
.theme--dark.v-text-field.v-input--is-disabled > .v-input__control > .v-input__slot:before .v-text-field__prefix,
.theme--dark.v-text-field.v-input--is-disabled > .v-input__control > .v-input__slot:before .v-text-field__suffix {
  color: rgba(255,255,255,0.5);
}
.theme--dark.v-text-field__prefix,
.theme--dark.v-text-field__suffix {
  color: rgba(255,255,255,0.7);
}
.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  border-radius: 2px;
  background: #424242;
}
.theme--dark.v-text-field--solo-inverted.v-text-field--solo > .v-input__control > .v-input__slot {
  background: rgba(255,255,255,0.16);
}
.theme--dark.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #fff;
}
.theme--dark.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot .v-label,
.theme--dark.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot input {
  color: rgba(0,0,0,0.87);
}
.theme--dark.v-text-field--box > .v-input__control > .v-input__slot {
  background: rgba(0,0,0,0.1);
}
.theme--dark.v-text-field--box .v-text-field__prefix {
  max-height: 32px;
  margin-top: 22px;
}
.theme--dark.v-text-field--box.v-input--is-dirty .v-text-field__prefix,
.theme--dark.v-text-field--box.v-input--is-focused .v-text-field__prefix,
.theme--dark.v-text-field--box.v-text-field--placeholder .v-text-field__prefix {
  margin-top: 22px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.theme--dark.v-text-field--box:not(.v-input--is-focused) > .v-input__control > .v-input__slot:hover {
  background: rgba(0,0,0,0.2);
}
.theme--dark.v-text-field--outline > .v-input__control > .v-input__slot {
  border: 2px solid rgba(255,255,255,0.7);
}
.theme--dark.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  border: 2px solid #fff;
}
.application--is-rtl .v-text-field .v-label {
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
.application--is-rtl .v-text-field .v-counter {
  margin-left: 0;
  margin-right: 8px;
}
.application--is-rtl .v-text-field--enclosed .v-input__append-outer {
  margin-left: 0;
  margin-right: 16px;
}
.application--is-rtl .v-text-field--enclosed .v-input__prepend-outer {
  margin-left: 16px;
  margin-right: 0;
}
.application--is-rtl .v-text-field--reverse input {
  text-align: left;
}
.application--is-rtl .v-text-field--reverse .v-label {
  -webkit-transform-origin: top left;
          transform-origin: top left;
}
.application--is-rtl .v-text-field__prefix {
  text-align: left;
  padding-right: 0;
  padding-left: 4px;
}
.application--is-rtl .v-text-field__suffix {
  padding-left: 0;
  padding-right: 4px;
}
.application--is-rtl .v-text-field--reverse .v-text-field__prefix {
  text-align: right;
  padding-left: 0;
  padding-right: 4px;
}
.application--is-rtl .v-text-field--reverse .v-text-field__suffix {
  padding-left: 0;
  padding-right: 4px;
}
.v-text-field {
  padding-top: 12px;
  margin-top: 4px;
}
.v-text-field input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
}
.v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  align-self: flex-start;
  display: inline-flex;
  margin-top: 4px;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-text-field .v-input__prepend-inner {
  margin-right: auto;
  padding-right: 4px;
}
.v-text-field .v-input__append-inner {
  margin-left: auto;
  padding-left: 4px;
}
.v-text-field .v-counter {
  margin-left: 8px;
  white-space: nowrap;
}
.v-text-field .v-label {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 6px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  white-space: nowrap;
  pointer-events: none;
}
.v-text-field .v-label--active {
  max-width: 133%;
  -webkit-transform: translateY(-18px) scale(0.75);
          transform: translateY(-18px) scale(0.75);
}
.v-text-field > .v-input__control > .v-input__slot {
  cursor: text;
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: '';
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: solid;
  border-width: thin 0 0 0;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  border-color: currentColor;
  border-style: solid;
  border-width: thin 0 thin 0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
.v-text-field__details {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  overflow: hidden;
}
.v-text-field__prefix,
.v-text-field__suffix {
  align-self: center;
  cursor: default;
}
.v-text-field__prefix {
  text-align: right;
  padding-right: 4px;
}
.v-text-field__suffix {
  padding-left: 4px;
  white-space: nowrap;
}
.v-text-field--reverse .v-text-field__prefix {
  text-align: left;
  padding-right: 0;
  padding-left: 4px;
}
.v-text-field--reverse .v-text-field__suffix {
  padding-left: 0;
  padding-right: 4px;
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}
.v-text-field--box,
.v-text-field--full-width,
.v-text-field--outline {
  position: relative;
}
.v-text-field--box > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outline > .v-input__control > .v-input__slot {
  align-items: stretch;
  min-height: 56px;
}
.v-text-field--box input,
.v-text-field--full-width input,
.v-text-field--outline input {
  margin-top: 22px;
}
.v-text-field--box.v-text-field--single-line input,
.v-text-field--full-width.v-text-field--single-line input,
.v-text-field--outline.v-text-field--single-line input {
  margin-top: 12px;
}
.v-text-field--box .v-label,
.v-text-field--full-width .v-label,
.v-text-field--outline .v-label {
  top: 18px;
}
.v-text-field--box .v-label--active,
.v-text-field--full-width .v-label--active,
.v-text-field--outline .v-label--active {
  -webkit-transform: translateY(-6px) scale(0.75);
          transform: translateY(-6px) scale(0.75);
}
.v-text-field--box > .v-input__control > .v-input__slot {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.v-text-field--box > .v-input__control > .v-input__slot:before {
  border-style: solid;
  border-width: thin 0 thin 0;
}
.v-text-field.v-text-field--enclosed {
  margin: 0;
  padding: 0;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--box) .v-progress-linear__background {
  display: none;
}
.v-text-field.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field.v-text-field--enclosed .v-input__append-inner,
.v-text-field.v-text-field--enclosed .v-input__append-outer {
  margin-top: 16px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details,
.v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding: 0 12px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 8px;
}
.v-text-field--reverse input {
  text-align: right;
}
.v-text-field--reverse .v-label {
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
.v-text-field--reverse > .v-input__control > .v-input__slot,
.v-text-field--reverse .v-text-field__slot {
  flex-direction: row-reverse;
}
.v-text-field--solo > .v-input__control > .v-input__slot:before,
.v-text-field--outline > .v-input__control > .v-input__slot:before,
.v-text-field--full-width > .v-input__control > .v-input__slot:before,
.v-text-field--solo > .v-input__control > .v-input__slot:after,
.v-text-field--outline > .v-input__control > .v-input__slot:after,
.v-text-field--full-width > .v-input__control > .v-input__slot:after {
  display: none;
}
.v-text-field--outline {
  margin-bottom: 16px;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-text-field--outline > .v-input__control > .v-input__slot {
  background: transparent !important;
  border-radius: 4px;
}
.v-text-field--outline .v-text-field__prefix {
  margin-top: 22px;
  max-height: 32px;
}
.v-text-field--outline .v-input__prepend-outer,
.v-text-field--outline .v-input__append-outer {
  margin-top: 18px;
}
.v-text-field--outline.v-input--is-dirty .v-text-field__prefix,
.v-text-field--outline.v-input--is-focused .v-text-field__prefix,
.v-text-field--outline.v-text-field--placeholder .v-text-field__prefix {
  margin-top: 22px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-text-field--outline.v-input--is-focused > .v-input__control > .v-input__slot,
.v-text-field--outline.v-input--has-state > .v-input__control > .v-input__slot {
  border: 2px solid currentColor;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-text-field.v-text-field--solo .v-label {
  top: calc(50% - 10px);
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 48px;
  padding: 0;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-text-field.v-text-field--solo .v-text-field__slot {
  align-items: center;
}
.v-text-field.v-text-field--solo .v-input__append-inner,
.v-text-field.v-text-field--solo .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
}
.v-text-field.v-text-field--solo .v-input__prepend-outer,
.v-text-field.v-text-field--solo .v-input__append-outer {
  margin-top: 12px;
}
.v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.v-text-field.v-input--has-state > .v-input__control > .v-input__slot:before {
  border-color: currentColor;
}
