.theme--light.v-navigation-drawer {
  background-color: #fff;
}
.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: rgba(0,0,0,0.12);
}
.theme--light.v-navigation-drawer .v-divider {
  border-color: rgba(0,0,0,0.12);
}
.theme--dark.v-navigation-drawer {
  background-color: #424242;
}
.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: rgba(255,255,255,0.12);
}
.theme--dark.v-navigation-drawer .v-divider {
  border-color: rgba(255,255,255,0.12);
}
.v-navigation-drawer {
  transition: none;
  display: block;
  left: 0;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: auto;
  top: 0;
  will-change: transform;
  z-index: 3;
  -webkit-overflow-scrolling: touch;
}
.v-navigation-drawer[data-booted="true"] {
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-navigation-drawer[data-booted="true"] {
  transition-property: width, -webkit-transform;
  transition-property: transform, width;
  transition-property: transform, width, -webkit-transform;
}
.v-navigation-drawer__border {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
}
.v-navigation-drawer.v-navigation-drawer--right:after {
  left: 0;
  right: initial;
}
.v-navigation-drawer--right {
  left: auto;
  right: 0;
}
.v-navigation-drawer--right > .v-navigation-drawer__border {
  right: auto;
  left: 0;
}
.v-navigation-drawer--absolute {
  position: absolute;
}
.v-navigation-drawer--fixed {
  position: fixed;
}
.v-navigation-drawer--floating:after {
  display: none;
}
.v-navigation-drawer--mini-variant {
  overflow: hidden;
}
.v-navigation-drawer--mini-variant .v-list__group__header__prepend-icon {
  flex: 1 0 auto;
  justify-content: center;
  width: 100%;
}
.v-navigation-drawer--mini-variant .v-list__tile__action,
.v-navigation-drawer--mini-variant .v-list__tile__avatar {
  justify-content: center;
  min-width: 48px;
}
.v-navigation-drawer--mini-variant .v-list__tile__content,
.v-navigation-drawer--mini-variant .v-list__tile:after {
  opacity: 0;
}
.v-navigation-drawer--mini-variant .v-subheader,
.v-navigation-drawer--mini-variant .v-divider,
.v-navigation-drawer--mini-variant .v-list--group {
  display: none !important;
}
.v-navigation-drawer--temporary,
.v-navigation-drawer--is-mobile {
  z-index: 6;
}
.v-navigation-drawer--temporary:not(.v-navigation-drawer--close),
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close) {
  box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
}
.v-navigation-drawer .v-list {
  background: inherit;
}
.v-navigation-drawer > .v-list .v-list__tile {
  transition: none;
  font-weight: 500;
}
.v-navigation-drawer > .v-list .v-list__tile--active .v-list__tile__title {
  color: inherit;
}
.v-navigation-drawer > .v-list .v-list--group .v-list__tile {
  font-weight: 400;
}
.v-navigation-drawer > .v-list .v-list--group__header--active:after {
  background: transparent;
}
.v-navigation-drawer > .v-list:not(.v-list--dense) .v-list__tile {
  font-size: 14px;
}
