.v-progress-linear {
  background: transparent;
  margin: 1rem 0;
  overflow: hidden;
  width: 100%;
  position: relative;
}
.v-progress-linear__bar {
  width: 100%;
  height: inherit;
  position: relative;
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  z-index: 1;
}
.v-progress-linear__bar__determinate {
  height: inherit;
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.v-progress-linear__bar__indeterminate .long,
.v-progress-linear__bar__indeterminate .short {
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  will-change: left, right;
  width: auto;
  background-color: inherit;
}
.v-progress-linear__bar__indeterminate--active .long {
  -webkit-animation: indeterminate;
          animation: indeterminate;
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.v-progress-linear__bar__indeterminate--active .short {
  -webkit-animation: indeterminate-short;
          animation: indeterminate-short;
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.v-progress-linear__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: 0.3s ease-in;
}
.v-progress-linear__content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.v-progress-linear--query .v-progress-linear__bar__indeterminate--active .long {
  -webkit-animation: query;
          animation: query;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.v-progress-linear--query .v-progress-linear__bar__indeterminate--active .short {
  -webkit-animation: query-short;
          animation: query-short;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes indeterminate {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@keyframes indeterminate {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@-webkit-keyframes query {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@keyframes query {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@-webkit-keyframes query-short {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}
@keyframes query-short {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}
