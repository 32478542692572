/* Theme */
.theme--light.v-toolbar {
  background-color: #f5f5f5;
  color: rgba(0,0,0,0.87);
}
.theme--dark.v-toolbar {
  background-color: #212121;
  color: #fff;
}
.application--is-rtl .v-toolbar__title:not(:first-child) {
  margin-left: 0;
  margin-right: 20px;
}
.v-toolbar {
  transition: none;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  position: relative;
  width: 100%;
  will-change: padding-left, padding-right;
}
.v-toolbar[data-booted="true"] {
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-toolbar .v-text-field--enclosed,
.v-toolbar .v-text-field--box {
  margin: 0;
}
.v-toolbar .v-text-field--enclosed .v-text-field__details,
.v-toolbar .v-text-field--box .v-text-field__details {
  display: none;
}
/** Children */
.v-toolbar .v-tabs {
  width: 100%;
}
.v-toolbar__title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-toolbar__title:not(:first-child) {
  margin-left: 20px;
}
.v-toolbar__content,
.v-toolbar__extension {
  align-items: center;
  display: flex;
  padding: 0 24px;
}
.v-toolbar__content .v-btn--icon,
.v-toolbar__extension .v-btn--icon {
  margin: 6px;
}
.v-toolbar__content > *:first-child,
.v-toolbar__extension > *:first-child {
  margin-left: 0;
}
.v-toolbar__content > *:first-child.v-btn--icon,
.v-toolbar__extension > *:first-child.v-btn--icon {
  margin-left: -6px;
}
.v-toolbar__content > *:first-child.v-menu .v-menu__activator .v-btn,
.v-toolbar__extension > *:first-child.v-menu .v-menu__activator .v-btn,
.v-toolbar__content > *:first-child.v-tooltip span .v-btn,
.v-toolbar__extension > *:first-child.v-tooltip span .v-btn {
  margin-left: 0;
}
.v-toolbar__content > *:first-child.v-menu .v-menu__activator .v-btn--icon,
.v-toolbar__extension > *:first-child.v-menu .v-menu__activator .v-btn--icon,
.v-toolbar__content > *:first-child.v-tooltip span .v-btn--icon,
.v-toolbar__extension > *:first-child.v-tooltip span .v-btn--icon {
  margin-left: -6px;
}
.v-toolbar__content > *:last-child,
.v-toolbar__extension > *:last-child {
  margin-right: 0;
}
.v-toolbar__content > *:last-child.v-btn--icon,
.v-toolbar__extension > *:last-child.v-btn--icon {
  margin-right: -6px;
}
.v-toolbar__content > *:last-child.v-menu .v-menu__activator .v-btn,
.v-toolbar__extension > *:last-child.v-menu .v-menu__activator .v-btn,
.v-toolbar__content > *:last-child.v-tooltip span .v-btn,
.v-toolbar__extension > *:last-child.v-tooltip span .v-btn {
  margin-right: 0;
}
.v-toolbar__content > *:last-child.v-menu .v-menu__activator .v-btn--icon,
.v-toolbar__extension > *:last-child.v-menu .v-menu__activator .v-btn--icon,
.v-toolbar__content > *:last-child.v-tooltip span .v-btn--icon,
.v-toolbar__extension > *:last-child.v-tooltip span .v-btn--icon {
  margin-right: -6px;
}
.v-toolbar__content > .v-list,
.v-toolbar__extension > .v-list {
  flex: 1 1 auto;
  max-height: 100%;
}
.v-toolbar__content > .v-list:first-child,
.v-toolbar__extension > .v-list:first-child {
  margin-left: -24px;
}
.v-toolbar__content > .v-list:last-child,
.v-toolbar__extension > .v-list:last-child {
  margin-right: -24px;
}
.v-toolbar__extension > .v-toolbar__title {
  margin-left: 72px;
}
.v-toolbar__items {
  display: flex;
  height: inherit;
  max-width: 100%;
  padding: 0;
}
.v-toolbar__items .v-btn {
  align-items: center;
  align-self: center;
}
.v-toolbar__items .v-tooltip,
.v-toolbar__items .v-tooltip > span {
  height: inherit;
}
.v-toolbar__items .v-btn:not(.v-btn--floating):not(.v-btn--icon),
.v-toolbar__items .v-menu,
.v-toolbar__items .v-menu__activator {
  height: inherit;
  margin: 0;
}
/** Types */
.v-toolbar .v-overflow-btn,
.v-toolbar .v-btn-toggle {
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.v-toolbar .v-input {
  margin: 0;
}
.v-toolbar .v-overflow-btn .v-input__control:before,
.v-toolbar .v-overflow-btn .v-input__slot:before {
  display: none;
}
.v-toolbar--card {
  border-radius: 2px 2px 0 0;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.v-toolbar--fixed {
  position: fixed;
  z-index: 2;
}
.v-toolbar--fixed,
.v-toolbar--absolute {
  top: 0;
  left: 0;
}
.v-toolbar--absolute {
  position: absolute;
  z-index: 2;
}
.v-toolbar--floating {
  display: inline-flex;
  margin: 16px;
  width: auto;
}
.v-toolbar--clipped {
  z-index: 3;
}
@media only screen and (max-width: 959px) {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0 16px;
  }
  .v-toolbar__content > .v-list:first-child,
  .v-toolbar__extension > .v-list:first-child {
    margin-left: -16px;
  }
  .v-toolbar__content > .v-list:last-child,
  .v-toolbar__extension > .v-list:last-child {
    margin-right: -16px;
  }
}
