.v-responsive {
  position: relative;
  overflow: hidden;
  flex: 1 0 auto;
  display: flex;
}
.v-responsive__content {
  flex: 1 0 0px;
}
.v-responsive__sizer {
  transition: padding-bottom 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  flex: 0 0 0px;
}
