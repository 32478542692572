.v-parallax {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.v-parallax__image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  contain: strict;
}
.v-parallax__image {
  position: absolute;
  bottom: 0;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  display: none;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  will-change: transform;
  transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
  z-index: 1;
}
.v-parallax__content {
  color: #fff;
  height: 100%;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
}
