.theme--light.v-data-iterator .v-data-iterator__actions {
  color: rgba(0,0,0,0.54);
}
.theme--light.v-data-iterator .v-data-iterator__actions__select .v-select .v-select__selection--comma {
  color: rgba(0,0,0,0.54) !important;
}
.theme--light.v-data-iterator .v-data-iterator__actions__select .v-select .v-input__append-inner {
  color: rgba(0,0,0,0.54) !important;
}
.theme--dark.v-data-iterator .v-data-iterator__actions {
  color: rgba(255,255,255,0.7);
}
.theme--dark.v-data-iterator .v-data-iterator__actions__select .v-select .v-select__selection--comma {
  color: rgba(255,255,255,0.7) !important;
}
.theme--dark.v-data-iterator .v-data-iterator__actions__select .v-select .v-input__append-inner {
  color: rgba(255,255,255,0.7) !important;
}
/** Actions */
.v-data-iterator__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  flex-wrap: wrap-reverse;
}
.v-data-iterator__actions .v-btn {
  color: inherit;
}
.v-data-iterator__actions .v-btn:last-of-type {
  margin-left: 14px;
}
.v-data-iterator__actions__range-controls {
  display: flex;
  align-items: center;
  min-height: 48px;
}
.v-data-iterator__actions__pagination {
  display: block;
  text-align: center;
  margin: 0 32px 0 24px;
}
.v-data-iterator__actions__select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 14px;
  white-space: nowrap;
}
.v-data-iterator__actions__select .v-select {
  flex: 0 1 0;
  margin: 13px 0 13px 34px;
  padding: 0;
  position: initial;
}
.v-data-iterator__actions__select .v-select__selections {
  flex-wrap: nowrap;
}
.v-data-iterator__actions__select .v-select__selections .v-select__selection--comma {
  font-size: 12px;
}
