/** Theme */
.theme--light.v-expansion-panel .v-expansion-panel__container {
  border-top: 1px solid rgba(0,0,0,0.12);
  background-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--light.v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon {
  color: rgba(0,0,0,0.54);
}
.theme--light.v-expansion-panel .v-expansion-panel__container--disabled {
  color: rgba(0,0,0,0.38);
}
.theme--light.v-expansion-panel--focusable .v-expansion-panel__container:focus {
  background-color: #eee;
}
.theme--dark.v-expansion-panel .v-expansion-panel__container {
  border-top: 1px solid rgba(255,255,255,0.12);
  background-color: #424242;
  color: #fff;
}
.theme--dark.v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon {
  color: #fff;
}
.theme--dark.v-expansion-panel .v-expansion-panel__container--disabled {
  color: rgba(255,255,255,0.5);
}
.theme--dark.v-expansion-panel--focusable .v-expansion-panel__container:focus {
  background-color: #494949;
}
.v-expansion-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  text-align: left;
  width: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.v-expansion-panel__container {
  flex: 1 0 100%;
  max-width: 100%;
  outline: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-expansion-panel__container:first-child {
  border-top: none !important;
}
.v-expansion-panel__container .v-expansion-panel__header__iconnel__header__icon {
  margin-left: auto;
}
.v-expansion-panel__container--disabled .v-expansion-panel__header {
  pointer-events: none;
}
.v-expansion-panel__container--active > .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-expansion-panel__header {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  padding: 12px 24px;
  min-height: 48px;
}
.v-expansion-panel__header > *:not(.v-expansion-panel__header__icon) {
  flex: 1 1 auto;
}
.v-expansion-panel__body {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-expansion-panel__body > .v-card {
  border-radius: 0;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
}
.v-expansion-panel--popout,
.v-expansion-panel--inset {
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.v-expansion-panel--popout .v-expansion-panel__container--active,
.v-expansion-panel--inset .v-expansion-panel__container--active {
  margin: 16px;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}
.v-expansion-panel--popout .v-expansion-panel__container,
.v-expansion-panel--inset .v-expansion-panel__container {
  max-width: 95%;
}
.v-expansion-panel--popout .v-expansion-panel__container--active {
  max-width: 100%;
}
.v-expansion-panel--inset .v-expansion-panel__container--active {
  max-width: 85%;
}
