/* Theme */
.theme--light.v-input--slider .v-slider__track,
.theme--light.v-input--slider .v-slider__track-fill {
  background: rgba(0,0,0,0.26);
}
.theme--light.v-input--slider .v-slider__track__container:after {
  border: 1px solid rgba(0,0,0,0.87);
}
.theme--light.v-input--slider .v-slider__ticks {
  border-color: rgba(0,0,0,0.87);
  color: rgba(0,0,0,0.54);
}
.theme--light.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb-label {
  background: rgba(0,0,0,0.26);
}
.theme--light.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb {
  border: 3px solid rgba(0,0,0,0.26);
}
.theme--light.v-input--slider:not(.v-input--is-dirty).v-input--slider--is-active .v-slider__thumb {
  border: 3px solid rgba(0,0,0,0.38);
}
.theme--light.v-input--slider.v-input--is-disabled .v-slider__thumb {
  border: 5px solid rgba(0,0,0,0.26);
}
.theme--light.v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb {
  background: rgba(0,0,0,0.26);
}
.theme--light.v-input--slider.v-input--slider--is-active .v-slider__track {
  background: rgba(0,0,0,0.38);
}
.theme--dark.v-input--slider .v-slider__track,
.theme--dark.v-input--slider .v-slider__track-fill {
  background: rgba(255,255,255,0.2);
}
.theme--dark.v-input--slider .v-slider__track__container:after {
  border: 1px solid #fff;
}
.theme--dark.v-input--slider .v-slider__ticks {
  border-color: #fff;
  color: rgba(255,255,255,0.7);
}
.theme--dark.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb-label {
  background: rgba(255,255,255,0.2);
}
.theme--dark.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb {
  border: 3px solid rgba(255,255,255,0.2);
}
.theme--dark.v-input--slider:not(.v-input--is-dirty).v-input--slider--is-active .v-slider__thumb {
  border: 3px solid rgba(255,255,255,0.3);
}
.theme--dark.v-input--slider.v-input--is-disabled .v-slider__thumb {
  border: 5px solid rgba(255,255,255,0.2);
}
.theme--dark.v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb {
  background: rgba(255,255,255,0.2);
}
.theme--dark.v-input--slider.v-input--slider--is-active .v-slider__track {
  background: rgba(255,255,255,0.3);
}
.application--is-rtl .v-input--slider .v-label {
  margin-left: 16px;
  margin-right: 0;
}
/** Input Group */
.v-input--slider {
  margin-top: 16px;
}
.v-input--slider.v-input--is-focused .v-slider__thumb-container--is-active:not(.v-slider__thumb-container--show-label):before {
  opacity: 0.2;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.v-input--slider.v-input--is-focused .v-slider__track {
  transition: none;
}
.v-input--slider.v-input--is-focused.v-input--slider--ticks .v-slider__track__container:after,
.v-input--slider.v-input--is-focused.v-input--slider--ticks .v-slider .v-slider__tick {
  opacity: 1;
}
.v-input--slider.v-input--slider--ticks .v-slider__ticks.v-slider__ticks--always-show {
  opacity: 1;
}
.v-input--slider.v-input--slider--ticks-labels .v-input__slot {
  margin-bottom: 16px;
}
.v-input--slider.v-input--is-readonly .v-input__control {
  pointer-events: none;
}
.v-input--slider.v-input--is-disabled .v-slider__thumb {
  -webkit-transform: translateY(-50%) scale(0.45);
          transform: translateY(-50%) scale(0.45);
}
.v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb {
  border: 0px solid transparent;
}
.v-input--slider .v-input__slot > *:first-child:not(:only-child) {
  margin-right: 16px;
}
/** Slider */
.v-slider {
  cursor: default;
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  flex: 1;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-slider input {
  cursor: default;
  opacity: 0;
  padding: 0;
  width: 100%;
}
/** Thumb/Track/Ticks */
.v-slider__track__container {
  height: 2px;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
}
.v-slider__track__container:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 2px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 2px;
  opacity: 0;
}
.v-slider__track,
.v-slider__thumb,
.v-slider__ticks {
  position: absolute;
  top: 0;
}
.v-slider__track {
  height: 2px;
  left: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-transform-origin: right;
          transform-origin: right;
  overflow: hidden;
  width: 100%;
}
.v-slider__track-fill {
  position: absolute;
  left: 0;
  height: 2px;
  -webkit-transform-origin: left;
          transform-origin: left;
  width: 100%;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-slider__ticks-container {
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.v-slider__ticks {
  opacity: 0;
  border-style: solid;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-slider__ticks > span {
  position: absolute;
  top: 8px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-slider__ticks:first-child > span {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.v-slider__ticks:last-child > span {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.v-slider:not(.v-input--is-dirty) .v-slider__ticks:first-child {
  border-color: transparent;
}
.v-slider__thumb-container {
  position: absolute;
  top: 50%;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-slider__thumb-container:before {
  content: '';
  color: inherit;
  border-radius: 50%;
  background: currentColor;
  height: 32px;
  left: -16px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: -16px;
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 32px;
  will-change: transform, opacity;
}
.v-slider__thumb {
  width: 24px;
  height: 24px;
  left: -12px;
  top: 50%;
  border-radius: 50%;
  background: transparent;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-transform: translateY(-50%) scale(0.6);
          transform: translateY(-50%) scale(0.6);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/** Thumb Label */
.v-slider--is-active .v-slider__thumb-container--is-active .v-slider__thumb {
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1);
}
.v-slider--is-active .v-slider__thumb-container--is-active.v-slider__thumb-container--show-label .v-slider__thumb {
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
}
.v-slider--is-active .v-slider__ticks-container .v-slider__ticks {
  opacity: 1;
}
.v-slider__thumb-label__container {
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-slider__thumb-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50% 50% 0;
  position: absolute;
  left: 0;
  bottom: 100%;
  -webkit-transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(45deg);
          transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(45deg);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-slider__thumb-label > * {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.v-slider__track,
.v-slider__track-fill {
  position: absolute;
}
