.application--is-rtl .v-badge__badge {
  right: initial;
  left: -22px;
}
.application--is-rtl .v-badge--overlap .v-badge__badge {
  right: initial;
  left: -8px;
}
.application--is-rtl .v-badge--overlap.v-badge--left .v-badge__badge {
  right: -8px;
  left: initial;
}
.application--is-rtl .v-badge--left .v-badge__badge {
  right: -22px;
  left: initial;
}
.v-badge {
  display: inline-block;
  position: relative;
}
.v-badge__badge {
  color: #fff;
  display: flex;
  position: absolute;
  font-size: 14px;
  top: -11px;
  right: -22px;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-badge__badge .v-icon {
  font-size: 14px;
}
.v-badge--overlap .v-badge__badge {
  top: -8px;
  right: -8px;
}
.v-badge--overlap.v-badge--left .v-badge__badge {
  left: -8px;
  right: initial;
}
.v-badge--overlap.v-badge--bottom .v-badge__badge {
  bottom: -8px;
  top: initial;
}
.v-badge--left .v-badge__badge {
  left: -22px;
}
.v-badge--bottom .v-badge__badge {
  bottom: -11px;
  top: initial;
}
