.application--is-rtl .v-carousel__prev {
  left: auto;
  right: 5px;
}
.application--is-rtl .v-carousel__next {
  left: 5px;
  right: auto;
}
.v-carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-carousel__prev,
.v-carousel__next {
  position: absolute;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.v-carousel__prev .v-btn,
.v-carousel__next .v-btn {
  margin: 0;
  height: auto;
  width: auto;
}
.v-carousel__prev .v-btn i,
.v-carousel__next .v-btn i {
  font-size: 48px;
}
.v-carousel__prev .v-btn:hover,
.v-carousel__next .v-btn:hover {
  background: none;
}
.v-carousel__prev {
  left: 5px;
}
.v-carousel__next {
  right: 5px;
}
.v-carousel__controls {
  background: rgba(0,0,0,0.5);
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  height: 50px;
  list-style-type: none;
  width: 100%;
  z-index: 1;
}
.v-carousel__controls > .v-item-group {
  flex: 0 1 auto;
}
.v-carousel__controls__item {
  margin: 0 8px !important;
}
.v-carousel__controls__item .v-icon {
  opacity: 0.5;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-carousel__controls__item--active .v-icon {
  opacity: 1;
  vertical-align: middle;
}
.v-carousel__controls__item:hover {
  background: none;
}
.v-carousel__controls__item:hover .v-icon {
  opacity: 0.8;
}
