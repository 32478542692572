/* Theme */
.theme--light.v-messages {
  color: rgba(0,0,0,0.54);
}
.theme--dark.v-messages {
  color: rgba(255,255,255,0.7);
}
.application--is-rtl .v-messages {
  text-align: right;
}
.v-messages {
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 12px;
  min-width: 1px;
  position: relative;
}
.v-messages__message {
  line-height: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
