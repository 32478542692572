.v-alert {
  border-radius: 0;
  border-width: 4px 0 0 0;
  border-style: solid;
  color: #fff;
  display: flex;
  font-size: 14px;
  margin: 4px auto;
  padding: 16px;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-alert .v-alert__icon.v-icon,
.v-alert__dismissible .v-icon {
  align-self: center;
  color: rgba(0,0,0,0.3);
  font-size: 24px;
}
.v-alert--outline .v-icon {
  color: inherit !important;
}
.v-alert__icon {
  margin-right: 16px;
}
.v-alert__dismissible {
  align-self: flex-start;
  color: inherit;
  margin-left: 16px;
  margin-right: 0;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-alert__dismissible:hover {
  opacity: 0.8;
}
.v-alert--no-icon .v-alert__icon {
  display: none;
}
.v-alert > div {
  align-self: center;
  flex: 1 1;
}
.v-alert.v-alert {
  border-color: rgba(0,0,0,0.12) !important;
}
.v-alert.v-alert--outline {
  border: 1px solid currentColor !important;
}
@media screen and (max-width: 600px) {
  .v-alert__icon {
    display: none;
  }
}
