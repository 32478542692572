.theme--light.v-small-dialog a {
  color: rgba(0,0,0,0.87);
}
.theme--dark.v-small-dialog a {
  color: #fff;
}
.theme--light.v-small-dialog__content {
  background: #fff;
}
.theme--dark.v-small-dialog__content {
  background: #424242;
}
.theme--light.v-small-dialog__actions {
  background: #fff;
}
.theme--dark.v-small-dialog__actions {
  background: #424242;
}
.v-small-dialog {
  display: block;
  width: 100%;
  height: 100%;
}
.v-small-dialog__content {
  padding: 0 24px;
}
.v-small-dialog__actions {
  text-align: right;
  white-space: pre;
}
.v-small-dialog a {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
}
.v-small-dialog a > * {
  width: 100%;
}
.v-small-dialog .v-menu__activator {
  height: 100%;
}
