/* Theme */
.theme--light.v-counter {
  color: rgba(0,0,0,0.54);
}
.theme--dark.v-counter {
  color: rgba(255,255,255,0.7);
}
.v-counter {
  flex: 0 1 auto;
  font-size: 12px;
  min-height: 12px;
  line-height: 1;
}
