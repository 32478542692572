/* Themes */
.theme--light.v-list {
  background: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--light.v-list .v-list--disabled {
  color: rgba(0,0,0,0.38);
}
.theme--light.v-list .v-list__tile__sub-title {
  color: rgba(0,0,0,0.54);
}
.theme--light.v-list .v-list__tile__mask {
  color: rgba(0,0,0,0.38);
  background: #eee;
}
.theme--light.v-list .v-list__tile--link:hover,
.theme--light.v-list .v-list__tile--highlighted,
.theme--light.v-list .v-list__group__header:hover {
  background: rgba(0,0,0,0.04);
}
.theme--light.v-list .v-list__group--active:before,
.theme--light.v-list .v-list__group--active:after {
  background: rgba(0,0,0,0.12);
}
.theme--light.v-list .v-list__group--disabled .v-list__tile {
  color: rgba(0,0,0,0.38) !important;
}
.theme--light.v-list .v-list__group--disabled .v-list__group__header__prepend-icon .v-icon {
  color: rgba(0,0,0,0.38) !important;
}
.theme--dark.v-list {
  background: #424242;
  color: #fff;
}
.theme--dark.v-list .v-list--disabled {
  color: rgba(255,255,255,0.5);
}
.theme--dark.v-list .v-list__tile__sub-title {
  color: rgba(255,255,255,0.7);
}
.theme--dark.v-list .v-list__tile__mask {
  color: rgba(255,255,255,0.5);
  background: #494949;
}
.theme--dark.v-list .v-list__tile--link:hover,
.theme--dark.v-list .v-list__tile--highlighted,
.theme--dark.v-list .v-list__group__header:hover {
  background: rgba(255,255,255,0.08);
}
.theme--dark.v-list .v-list__group--active:before,
.theme--dark.v-list .v-list__group--active:after {
  background: rgba(255,255,255,0.12);
}
.theme--dark.v-list .v-list__group--disabled .v-list__tile {
  color: rgba(255,255,255,0.5) !important;
}
.theme--dark.v-list .v-list__group--disabled .v-list__group__header__prepend-icon .v-icon {
  color: rgba(255,255,255,0.5) !important;
}
.application--is-rtl .v-list__tile__title {
  text-align: right;
}
.application--is-rtl .v-list__tile__content {
  text-align: right;
}
.v-list {
  list-style-type: none;
  padding: 8px 0 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-list > div {
  transition: inherit;
}
.v-list__tile {
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 48px;
  margin: 0;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-list__tile--link {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-list__tile__content,
.v-list__tile__action {
  height: 100%;
}
.v-list__tile__title,
.v-list__tile__sub-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-list__tile__title {
  height: 24px;
  line-height: 24px;
  position: relative;
  text-align: left;
}
.v-list__tile__sub-title {
  font-size: 14px;
}
.v-list__tile__avatar {
  display: flex;
  justify-content: flex-start;
  min-width: 56px;
}
.v-list__tile__action {
  display: flex;
  justify-content: flex-start;
  min-width: 56px;
  align-items: center;
}
.v-list__tile__action .v-btn {
  padding: 0;
  margin: 0;
}
.v-list__tile__action .v-btn--icon {
  margin: -6px;
}
.v-list__tile__action .v-radio.v-radio {
  margin: 0;
}
.v-list__tile__action .v-input--selection-controls {
  padding: 0;
  margin: 0;
}
.v-list__tile__action .v-input--selection-controls .v-messages {
  display: none;
}
.v-list__tile__action .v-input--selection-controls .v-input__slot {
  margin: 0;
}
.v-list__tile__action-text {
  color: #9e9e9e;
  font-size: 12px;
}
.v-list__tile__action--stack {
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  flex-direction: column;
}
.v-list__tile__content {
  text-align: left;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.v-list__tile__content ~ .v-list__tile__avatar {
  justify-content: flex-end;
}
.v-list__tile__content ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
  justify-content: flex-end;
}
.v-list__tile--active .v-list__tile__action:first-of-type .v-icon {
  color: inherit;
}
.v-list__tile--avatar {
  height: 56px;
}
.v-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
}
.v-list--dense .v-subheader {
  font-size: 13px;
  height: 40px;
}
.v-list--dense .v-list__group .v-subheader {
  height: 40px;
}
.v-list--dense .v-list__tile {
  font-size: 13px;
}
.v-list--dense .v-list__tile--avatar {
  height: 48px;
}
.v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
  height: 40px;
}
.v-list--dense .v-list__tile .v-icon {
  font-size: 22px;
}
.v-list--dense .v-list__tile__sub-title {
  font-size: 13px;
}
.v-list--disabled {
  pointer-events: none;
}
.v-list--two-line .v-list__tile {
  height: 72px;
}
.v-list--two-line.v-list--dense .v-list__tile {
  height: 60px;
}
.v-list--three-line .v-list__tile {
  height: 88px;
}
.v-list--three-line .v-list__tile__avatar {
  margin-top: -18px;
}
.v-list--three-line .v-list__tile__sub-title {
  white-space: initial;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}
.v-list--three-line.v-list--dense .v-list__tile {
  height: 76px;
}
.v-list > .v-list__group:before {
  top: 0;
}
.v-list > .v-list__group:before .v-list__tile__avatar {
  margin-top: -14px;
}
.v-list__group {
  padding: 0;
  position: relative;
  transition: inherit;
}
.v-list__group:before,
.v-list__group:after {
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-list__group--active ~ .v-list__group:before {
  display: none;
}
.v-list__group__header {
  align-items: center;
  cursor: pointer;
  display: flex;
  list-style-type: none;
}
.v-list__group__header > div:not(.v-list__group__header__prepend-icon):not(.v-list__group__header__append-icon) {
  flex: 1 1 auto;
  overflow: hidden;
}
.v-list__group__header .v-list__group__header__append-icon,
.v-list__group__header .v-list__group__header__prepend-icon {
  padding: 0 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-list__group__header--sub-group {
  align-items: center;
  display: flex;
}
.v-list__group__header--sub-group div .v-list__tile {
  padding-left: 0;
}
.v-list__group__header--sub-group .v-list__group__header__prepend-icon {
  padding: 0 0 0 40px;
  margin-right: 8px;
}
.v-list__group__header .v-list__group__header__prepend-icon {
  display: flex;
  justify-content: flex-start;
  min-width: 56px;
}
.v-list__group__header--active .v-list__group__header__append-icon .v-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-list__group__header--active .v-list__group__header__prepend-icon .v-icon {
  color: inherit;
}
.v-list__group__header--active.v-list__group__header--sub-group .v-list__group__header__prepend-icon .v-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-list__group__items {
  position: relative;
  padding: 0;
  transition: inherit;
}
.v-list__group__items > div {
  display: block;
}
.v-list__group__items--no-action .v-list__tile {
  padding-left: 72px;
}
.v-list__group--disabled {
  pointer-events: none;
}
.v-list--subheader {
  padding-top: 0;
}
