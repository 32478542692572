.v-speed-dial {
  position: relative;
}
.v-speed-dial--absolute {
  position: absolute;
}
.v-speed-dial--fixed {
  position: fixed;
}
.v-speed-dial--fixed,
.v-speed-dial--absolute {
  z-index: 4;
}
.v-speed-dial--fixed > .v-btn--floating,
.v-speed-dial--absolute > .v-btn--floating {
  margin: 0;
}
.v-speed-dial--top:not(.v-speed-dial--absolute) {
  top: 16px;
}
.v-speed-dial--top.v-speed-dial--absolute {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.v-speed-dial--bottom:not(.v-speed-dial--absolute) {
  bottom: 16px;
}
.v-speed-dial--bottom.v-speed-dial--absolute {
  bottom: 50%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.v-speed-dial--left {
  left: 16px;
}
.v-speed-dial--right {
  right: 16px;
}
.v-speed-dial--direction-left .v-speed-dial__list,
.v-speed-dial--direction-right .v-speed-dial__list {
  height: 100%;
  top: 0;
}
.v-speed-dial--direction-top .v-speed-dial__list,
.v-speed-dial--direction-bottom .v-speed-dial__list {
  left: 0;
  width: 100%;
}
.v-speed-dial--direction-top .v-speed-dial__list {
  flex-direction: column-reverse;
  bottom: 100%;
}
.v-speed-dial--direction-right .v-speed-dial__list {
  flex-direction: row;
  left: 100%;
}
.v-speed-dial--direction-bottom .v-speed-dial__list {
  flex-direction: column;
  top: 100%;
}
.v-speed-dial--direction-left .v-speed-dial__list {
  flex-direction: row-reverse;
  right: 100%;
}
/** Elements */
.v-speed-dial__list {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
}
